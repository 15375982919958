import "./styles.css";
import Navbar from "./components/layout/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SupportForm from "./components/layout/SupportForm";

export default function App() {
  return (
    <div className="App">
      <Router>
        <Navbar title="Rossouw" />
        <h1 className="donate">Support rosssouw.p</h1>
        <SupportForm />
      </Router>
    </div>
  );
}
