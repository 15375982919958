import { StrictMode } from "react";
import ReactDOM from "react-dom";

import App from "./App";

const script = document.createElement("script");

script.src = "https://js.paystack.co/v1/inline.js";
script.async = true;

document.body.appendChild(script);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
);

var paymentForm = document.getElementById("paymentForm");
paymentForm.addEventListener("submit", payWithPaystack, false);
function payWithPaystack() {
  var handler = PaystackPop.setup({
    key: "pk_live_2707c1d918dd0c56629485d25d0e9bd13e7da44c", // Replace with your public key
    email: document.getElementById("email-address").value,
    amount: document.getElementById("amount").value * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
    currency: "ZAR", // Use GHS for Ghana Cedis or USD for US Dollars
    ref: "_" + Math.random().toString(36).substr(2, 9), // Replace with a reference you generated
    callback: function (response) {
      //this happens after the payment is completed successfully
      var reference = response.reference;
      alert("Payment complete! Reference: " + reference);
      // Make an AJAX call to your server with the reference to verify the transaction
    },
    onClose: function () {
      alert("Transaction was not completed, window closed.");
    }
  });
  handler.openIframe();
}
