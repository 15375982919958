import React, { useContext, useState, useEffect } from "react";
import SupportContext from "../../context/supportContext";

const ContactForm = () => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    type: "personal"
  });

  const { name, email, amount, type } = contact;

  const onChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setContact({
      name: "",
      email: "",
      phone: "",
      type: "personal"
    });
  };

  const clearAll = () => {};

  return (
    <form id="paymentForm" onSubmit={onSubmit} className="card">
      <input
        type={"email"}
        placeholder={"Email"}
        name={"email"}
        value={email}
        id="email-address"
        onChange={onChange}
      />
      <input
        type="text"
        pattern="[0-9]*"
        placeholder={"Amount"}
        name={"amount"}
        value={amount}
        id="amount"
        onChange={onChange}
      />

      <div>
        <input
          type={"submit"}
          value={"Support"}
          className={"btn btn-dark btn-block"}
        />
      </div>
    </form>
  );
};

export default ContactForm;
