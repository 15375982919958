import React from "react";
import logo from "../../images/RossouwP-rgb.jpg";

const Navbar = ({}) => {
  return (
    <div className={"navbar "}>
      <h1>
        <img
          alt=""
          src={logo}
          style={{
            maxWidth: "100px",
            align: "center"
          }}
        />{" "}
      </h1>
      <ul>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://instagram.com/rossouw.p/"
          >
            <span className="btn btn-dark" style={{}}>
              Follow
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};
export default Navbar;
